<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1">
      <ul
        v-if="$store.state.appConfig.layout.type === 'vertical'"
        class="nav navbar-nav"
      >
        <li
          v-if="$store.state.appConfig.layout.menu.hidden === false"
          class="nav-item d-xl-none"
        >
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <b-img-lazy
              v-if="isDark"
              src="@/assets/images/logo/plexus-logo-dark.png"
              alt="Plexus Cloud Automation"
              height="36px"
            />
            <b-img-lazy
              v-else
              src="@/assets/images/logo/plexus-logo.png"
              alt="Plexus Cloud Automation"
              height="36px"
            />
          </b-link>
        </li>
        <li>
          <b-img-lazy
            v-if="$store.state.appConfig.layout.menu.hidden === true && isDark"
            src="@/assets/images/logo/plexus-easy-automation-dark.png"
            alt="Plexus Cloud Automation"
            height="40px"
          />
          <b-img-lazy
            v-if="$store.state.appConfig.layout.menu.hidden === true && !isDark"
            src="@/assets/images/logo/plexus-easy-automation.png"
            alt="Plexus Cloud Automation"
            height="40px"
          />
        </li>
      </ul>
    </div>

    <div v-if="!isSmall && projectName != ''" class="mt-25">
      <h4 class="mr-2 mb-0 project-name" style="line-height: 13px;">{{projectName}}</h4>
      <span class="user-level">{{userLevel}}</span>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="user-nav mr-0">
            <p class="user-name font-weight-bolder mb-0">
              <b-badge pill variant="primary" class="mr-50" v-if="showNotificationContainer"><span class="font-size-smaller">{{allNotificationsCount}}</span></b-badge>{{ userName }}
            </p>
            <span class="user-status">Free tier</span>
            <span v-if="isSmall && projectName != ''" class="project-name">{{projectName}} | {{userLevel}}</span>
          </div>
        </template>

        <notification-box v-if="showNotificationContainer" />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToProfilePage"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-1"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item>
          <dark-toggler-custom />
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$store.dispatch('plexus/logout')"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-1"
          />
          <span>Logout</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="contactUs">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-1"
          />
          <span>Contact us</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="openDocumentation">
          <feather-icon
            size="16"
            icon="BookIcon"
            class="mr-1"
          />
          <span>Documentation</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BImgLazy, BBadge,
} from 'bootstrap-vue'
import DarkTogglerCustom from '@/layouts/components/DarkTogglerCustom.vue'
import NotificationBox from '@/components/NotificationBox'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BImgLazy,
    BBadge,
    NotificationBox,

    // Navbar Components
    DarkTogglerCustom,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { /* */},
    },
  },
  computed: {
    userName() {
      return this.$store.state.plexus.user != null ? this.$store.state.plexus.user.name : ""
    },
    showNotificationContainer() {
      return this.$store.getters['notificator/allNotificationsCount'] > 0
    },
    allNotificationsCount() {
      const value = this.$store.getters['notificator/allNotificationsCount']
      return value > 99 ? "99+" : value.toString()
    },
    isDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
    projectName() {
      return this.$store.state.plexus.project?.name || ""
    },
    userLevel() {
      return this.$store.state.plexus.project?.levelString || ""
    },
    isSmall() {
      return this.$store.getters['app/currentBreakPoint'] == "xs" || this.$store.getters['app/currentBreakPoint'] == "sm"
    },
  },
  methods: {
    contactUs() {
      window.location.href = 'mailto:automation@bioforcetech.com?subject=PlexusCloud%20Request&body=message%20goes%20here'
    },
    openDocumentation() {
      window.open('https://docs.v1.plexus-automation.com/en/cloud', '_blank', undefined, true).focus();
    },
    goToProfilePage() {
      this.$router.push({
        name: 'profile',
        replace: true
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.project-name {
  font-weight: lighter;
  margin-top: 3px;
}
.user-nav .project-name {
  font-size: smaller;
}
.user-level {
  font-weight: lighter;
  font-size: smaller;
}
</style>
