





































import lodash from 'lodash'
import BaseComponent from '@/components/BaseComponent'
import Component from 'vue-class-component'
import {
  BNavbarNav, BNavItemDropdown,
} from 'bootstrap-vue'
import { ProjectNotificationStatus } from '@/models/ProjectNotificationStatus'

@Component({
  components: {
    BNavbarNav, BNavItemDropdown,
  },
})
export default class NotificationBox extends BaseComponent {
  get projectsWithNotificationsToShow(): ProjectNotificationStatus[] {
    return lodash.take(this.projectsWithNotifications,
      this.projectsWithNotifications.length > 5 ? 4 : this.projectsWithNotifications.length);
  }
  get projectsWithNotifications(): ProjectNotificationStatus[] {
    const value = this.$store.getters['notificator/notifications']
    if (value == null) return []

    return value.filter((x: ProjectNotificationStatus) => x.totalCount > 0);
  }
  get isDanger(): boolean {
    return this.projectsWithNotifications.find(x => x.alarmCount > 0) != null
  }
  goToProjectAlarm(projectId: number): void {
    this.$router.push({
      name: 'alarms',
      params: {
        projectId: projectId.toString()
      }
    })
  }
}
