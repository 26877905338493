<template>
  <div
    class="d-flex align-items-center"
    @click="toggleNightMode($event)"
  >
    <feather-icon
      size="16"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
      class="mr-1"
    />
    <span>{{ isDark ? 'Day' : 'Night' }} mode</span>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  methods: {
    toggleNightMode(event) {
      this.skin = this.isDark ? 'light' : 'dark'
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
</script>
