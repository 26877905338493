export default [
  {
    title: 'Projects',
    route: 'projects',
    icon: 'HomeIcon',
  },
  {
    header: 'Project areas',
  },
  {
    title: 'Devices',
    route: 'devices',
    icon: 'ServerIcon',
  },
  {
    title: 'Blackbox',
    route: 'blackboxes',
    icon: 'BoxIcon',
    renderCheckCallback: ({project}) => project.allDevicesCount >= 1,
  },
  {
    title: 'Mixed Charts',
    route: 'mixedCharts',
    icon: 'CodesandboxIcon',
    renderCheckCallback: ({project}) => project.allDevicesCount >= 2,
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'ClipboardIcon',
    renderCheckCallback: ({project}) => project.allDevicesCount >= 1,
  },
  {
    title: 'Alerts',
    route: 'alarms',
    icon: 'BellIcon',
    renderCheckCallback: ({project}) => project.allDevicesCount >= 1,
  },
  {
    title: 'Backups',
    route: 'backups',
    icon: 'ArchiveIcon',
    renderCheckCallback: ({project}) => project.allDevicesCount >= 1,
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    renderCheckCallback: ({project}) => project.amiSupervisor,
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
